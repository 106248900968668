import React from "react";
import PropTypes from "prop-types";
import WhatsAppImg from "./WhatsAppImg";
import "./style.css";

const WhatsAppButton = ({ stick }) => {
    const whatsAppLink =
        "https://wa.me/525544386040?text=%C2%A1Qu%C3%A9+tal%21+Quisiera+solicitar+m%C3%A1s+informaci%C3%B3n+sobre+sus+servicios.";

    return (
        <a href={whatsAppLink} target="_blank" rel="noreferrer">
            <button
                type="button"
                className="backto-top"
                style={{
                    opacity: stick ? 1 : 0,
                }}
            >
                <div
                    style={{
                        bottom: "100px",
                        backgroundColor: "white",
                    }}
                    className="whatsapp-button-svg"
                >
                    <WhatsAppImg />
                </div>
            </button>
        </a>
    );
};

WhatsAppButton.propTypes = {
    stick: PropTypes.bool,
};

export default WhatsAppButton;
